exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-cn-about-tsx": () => import("./../../../src/pages/cn/about.tsx" /* webpackChunkName: "component---src-pages-cn-about-tsx" */),
  "component---src-pages-cn-contact-tsx": () => import("./../../../src/pages/cn/contact.tsx" /* webpackChunkName: "component---src-pages-cn-contact-tsx" */),
  "component---src-pages-cn-index-tsx": () => import("./../../../src/pages/cn/index.tsx" /* webpackChunkName: "component---src-pages-cn-index-tsx" */),
  "component---src-pages-cn-resources-tsx": () => import("./../../../src/pages/cn/resources.tsx" /* webpackChunkName: "component---src-pages-cn-resources-tsx" */),
  "component---src-pages-cn-services-tsx": () => import("./../../../src/pages/cn/services.tsx" /* webpackChunkName: "component---src-pages-cn-services-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-resources-tsx": () => import("./../../../src/pages/resources.tsx" /* webpackChunkName: "component---src-pages-resources-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */)
}

